.app {
  background-color: #fff;
  font-family     : "PingFang SC";
}

#chart-mom,
#chart-baby {
  height    : 180px;
  width     : 100%;
  margin-top: -13px;
}

.banner {
  position        : relative;
  background-color: #fd6788;

  .date {
    position  : absolute;
    width     : 100%;
    top       : 162px;
    text-align: center;
    color     : #fff;
    font-size : 15px;

    span {
      margin-left : 10px;
      margin-right: 10px;
    }
  }
}

.info-box {
  padding: 15px;

  &-inner {
    position        : relative;
    padding         : 15px;
    background-color: #fff;
    border-radius   : 15px;
  }

  &-desc {
    margin-top: 10px;
    color     : #696969;
    font-size : 14px;

    .highlight {
      color      : #FF6A87;
      font-size  : 20px;
      font-weight: bold;
      padding    : 0 3px;
    }

    p {
      line-height: 1.5;
    }
  }

  &-title {
    margin-bottom: 20px;
    text-align   : center;

    &-container {
      display: inline-block;
    }

    &-box {
      padding        : 15px 14px;
      border-radius  : 25px;
      display        : flex;
      align-items    : center;
      justify-content: center;
      box-shadow     : 0 2px 5px 0px rgba(0, 0, 0, 0.1);
      background     : #fff;
      overflow       : hidden;
    }

    &-icon {
      width           : 19px;
      height          : 19px;
      border-radius   : 4px;
      margin-right    : 4px;
      background-image: linear-gradient(#FF6285, #FFCD9F);
    }

    &-text {
      color      : #FF6A87;
      font-size  : 14px;
      font-weight: bold;
    }
  }

  .cover {
    border-radius: 15px;
    overflow     : hidden;
    margin-bottom: 15px;

    height: 188px;
    width : 315px;

    background-repeat  : no-repeat;
    background-position: center center;
    background-size    : cover;
  }

  &-text {
    color        : #696969;
    font-size    : 14px;
    margin-bottom: 100px;

    p {
      margin-bottom: 20px;
    }

    .highlight {
      color      : #FF6A87;
      font-size  : 22px;
      font-weight: bold;
      padding    : 0 3px;
    }

    .summary {
      position  : absolute;
      bottom    : 5px;
      right     : 15px;
      text-align: right;

      .highlight {
        font-size: 28px;
      }
    }
  }

  .bg-decoration {
    width : 119px;
    height: 115px;
  }

  .bg-left-bottom {
    position : absolute;
    bottom   : 0;
    left     : 0;
    transform: rotate(180deg);
  }

  .bg-right-top {
    position: absolute;
    top     : 0;
    right   : 0;
  }

}

.bg {
  background-image: linear-gradient(#fd6788, #FFCD9F);
}

.info-box-01 {
  margin-top         : -40px;
  // background-image: linear-gradient(#fd6788, #fd7b8d);
}

.info-box-02 {
  .cover {
    margin-top: -68px;
  }
}

// .info-box-02 {
//   background-image: linear-gradient(#fd7b8d, #fa8b8f);
// }

// .info-box-03 {
//   background-image: linear-gradient(#fa8b8f, #f99f93);
// }

// .info-box-04 {
//   background-image: linear-gradient(#f99f93, #f99f93);
// }

.happy-moments {
  &-img {
    width        : 315px;
    height       : 188px;
    overflow     : hidden;
    border-radius: 10px;

    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center center;
  }

  &-flex {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    margin-bottom  : 7px;

    .happy-moments-img {
      width : 154px;
      height: 91px;
    }
  }

  &-normal {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    flex-wrap      : wrap;
    margin-bottom  : 7px;

    .happy-moments-img {
      flex         : 0 1 none;
      width        : 154px;
      height       : 91px;
      margin-bottom: 7px;
    }
  }
}

.info-box-06 {
  h2 {
    font-size    : 20px;
    text-align   : center;
    font-weight  : bold;
    line-height  : 1.5;
    color        : #333;
    margin-bottom: 5px;
  }

  .grey {
    color     : #696969;
    font-size : 12px;
    text-align: center;
  }

  .qrcode {
    display      : block;
    width        : 100px;
    margin-left  : auto;
    margin-right : auto;
    margin-top   : 20px;
    margin-bottom: 10px;
  }

  .img-brand {
    width              : 315px;
    height             : 177px;
    border-radius      : 15px;
    margin-bottom      : 20px;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center center;
    background-image   : url(https://manyuejia-1302886832.file.myqcloud.com/assets/brand.jpeg)
  }

  .scan {
    width           : 100px;
    color           : #000;
    text-align      : center;
    background-color: #F7F7F7;
    border-radius   : 20px;
    padding         : 3px 0;
    font-size       : 12px;
    margin-left     : auto;
    margin-right    : auto;
  }
}